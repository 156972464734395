import React, { useState, useEffect, useRef } from 'react';
import '../App.scss';

import { ReactComponent as ElectricityIcon } from '../svg/icon1.svg';
import { ReactComponent as TelecomunicationIcon } from '../svg/icon2.svg';
import { ReactComponent as SmartHomeIcon } from '../svg/icon3.svg';
import { ReactComponent as EarthWorkIcon } from '../svg/icon4.svg';
import { ReactComponent as LightiningIcon } from '../svg/icon5.svg';
import { ReactComponent as FireIcon } from '../svg/icon6.svg';
import { ReactComponent as ServiceIcon } from '../svg/icon7.svg';
import { ReactComponent as CalculatingeIcon } from '../svg/icon8.svg';
import { ReactComponent as CabinetIcon } from '../svg/icon9.svg';
import { ReactComponent as TestingIcon } from '../svg/icon10.svg';

const icons = [
  { Component: ElectricityIcon, label: "Instalacje elektryczne", imageUrl: "../instalacje.jpg" },
  { Component: CabinetIcon, label: "Prefabrykacja rozdzielnic", imageUrl: "../rozdzielnica.jpg" },
  { Component: TelecomunicationIcon, label: "Instalacje teletechniczne", imageUrl: "../teletechnics.jpg" },
  { Component: CalculatingeIcon, label: "Kosztorysowanie robót", imageUrl: "../kosztorysowanie.jpg" },
  { Component: FireIcon, label: "Instalacje przeciwpożarowe", imageUrl: "../przeciwpozarowe.jpg" },
  { Component: LightiningIcon, label: "Instalacje odgromowe", imageUrl: "../odgromowe.jpg" },
  { Component: SmartHomeIcon, label: "Instalacje smart-home", imageUrl: "../smarthome.jpg" },
  { Component: EarthWorkIcon, label: "Prace ziemne", imageUrl: "../ziemne.jpg" },
  { Component: ServiceIcon, label: "Serwis instalacji", imageUrl: "../serwis.jpg"},
];


const Home = ({active}) => {

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkWindowWidth = () => {
      if (window.innerWidth <= 480){
        setIsMobile(true)
      } else{
        setIsMobile(false)
      }
    }

    checkWindowWidth();

    window.addEventListener('resize', checkWindowWidth);
    return () => window.removeEventListener('resize', checkWindowWidth);
  }, [window]);


const MySlider = () => {
  const sliderGroupRef = useRef(null);

  const logos = [
    { src: "/archicom-logo.png" },
    { src: "/archiinwest-logo.png" },
    { src: "/chemobudowa-logo.png" },
    { src: "/domconstruction-logo.png" },
    { src: "/echoinvestments-logo.png" },
    { src: "/erbud-logo.png" },
    { src: "/instal-logo.png" },
    { src: "/interbud-logo.png" },
    { src: "/krakbau-logo.png" },
    { src: "/krisbud-logo.png" },
    { src: "/lokumdeveloper-logo.png" },
    { src: "/legprzem-logo.png" },
    { src: "/proton-logo.png" },
    { src: "/r4r-logo.png" },
    { src: "/zabbud-logo.png" },
    { src: "/archicom-logo.png" },
    { src: "/archiinwest-logo.png" },
    { src: "/chemobudowa-logo.png" },
    { src: "/domconstruction-logo.png" },
    { src: "/echoinvestments-logo.png" },
    { src: "/erbud-logo.png" },
    { src: "/instal-logo.png" },
    { src: "/interbud-logo.png" },
    { src: "/krakbau-logo.png" },
    { src: "/krisbud-logo.png" },
    { src: "/lokumdeveloper-logo.png" },
    { src: "/legprzem-logo.png" },
    { src: "/proton-logo.png" },
    { src: "/r4r-logo.png" },
    { src: "/zabbud-logo.png" },
    { src: "/archicom-logo.png" },
    { src: "/archiinwest-logo.png" },
    { src: "/chemobudowa-logo.png" },
    { src: "/domconstruction-logo.png" },
    { src: "/echoinvestments-logo.png" },
    { src: "/erbud-logo.png" },
    { src: "/instal-logo.png" },
    { src: "/interbud-logo.png" },
    { src: "/krakbau-logo.png" },
    { src: "/krisbud-logo.png" },
    { src: "/lokumdeveloper-logo.png" },
    { src: "/legprzem-logo.png" },
    { src: "/proton-logo.png" },
    { src: "/r4r-logo.png" },
    { src: "/zabbud-logo.png" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  }, []); 

  useEffect(() => {
    const sliderGroup = sliderGroupRef.current;

    if (!sliderGroup) return;
    const totalWidth = sliderGroup.scrollWidth / 2;

    let currentPosition = 0;

    const animate = () => {
      currentPosition -= isMobile ? 1 : 0.5; 

      if (Math.abs(currentPosition) >= totalWidth) {

        currentPosition = 0;
      }

      sliderGroup.style.transform = `translateX(${currentPosition}px)`;
      requestAnimationFrame(animate);
    };

    animate();

    return () => cancelAnimationFrame(animate); 
  }, [isMobile]);

  return (
    <div className="content-slider">
      <div className="slider">
        <div className="slider-group" ref={sliderGroupRef}>
          {[...logos, ...logos].map((icon, index) => (
            <div className="slider-card" key={index}>
              <img src={icon.src} alt={icon.label} />
              <p>{icon.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


  return (
    <div className={`elger-home ${active}`}>
      <div className="home-content">
        <div className='home-content-info'>
          <div className="services-grid">
            {icons.map((icon, index) => (
            <div className="service-card" key={index}>
              <div className="service-card-inner">
                <div className="service-card-front">
                  <img src={icon.imageUrl} alt={icon.label} width="80px" height="80px" className="service-image" />
                </div>
                <div className="service-card-back">
                  <icon.Component className="service-icon" />
                  <p>{icon.label}</p>
                </div>
              </div>
            </div>
            ))}
          </div>
          <div className="info-section">
            <div className="info-container">
              <div className="text-column">
                <p className='text'>
                  <h2>Kim jesteśmy?</h2>
                  Od 2001 roku z powodzeniem działamy na rynku, specjalizując się w&nbsp;kompleksowym wykonawstwie 
                  instalacji elektrycznych.

                  Zaufanie największych firm budowlanych i&nbsp;deweloperów w&nbsp;Polsce, 
                  jak również klientów indywidualnych, pozwala nam realizować projekty 
                  w&nbsp;budynkach mieszkalnych, biurowych, usługowych, hotelowych oraz 
                  w&nbsp;obiektach użyteczności publicznej.

                  Naszą siłą jest wysoko wykwalifikowana kadra, kierownicy robót, 
                  inżynierowie i&nbsp;elektromonterzy, posiadający niezbędne uprawnienia 
                  oraz wieloletnie doświadczenie. Dzięki nowoczesnemu zapleczu technicznemu 
                  i&nbsp;rzetelnemu podejściu do&nbsp;każdego zlecenia, zapewniamy terminową 
                  realizację prac oraz profesjonalną obsługę gwarancyjną.
                </p>
                <p className="services">
                  <h2>Nasze usługi</h2>
                  {icons.map((icon, index) => (
                    <span 
                      className='box'
                      key={index}
                      style={{
                        backgroundImage: `url(${icon.imageUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "inline-block",
                        textAlign: "center",
                      }}
                    >
                      <span className='text'>{icon.label}</span>
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </div>
          <div className='home-middle'>
          <p className="content-title"><span>Zaufali nam</span></p>
              <MySlider/>
        <p className="content-title"></p>
        </div>
        <div className='home-bottom'>
          <span>Od ponad 20 lat naszym niezmiennym celem jest zadowolenie klienta&nbsp;</span>
          <span>- zapraszamy do współpracy!</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
