import React from 'react';

const PageTitle = ({text}) => {

    return (
        <div className='page-title' style={{
            backgroundImage: `url(../headerekmini.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat'}}>
            <p>
                {text}
            </p>
        </div>
    );
  };
  
  export default PageTitle;