import React, { useEffect } from 'react';

import { ReactComponent as Rozwoj } from '../svg/rozwoj.svg';
import { ReactComponent as Bezpieczenstwo } from '../svg/bezpieczenstwo.svg';
import { ReactComponent as Ubezpieczenia } from '../svg/ubezpieczenia.svg';
import { ReactComponent as Motywacja } from '../svg/motywacja.svg';

import PageTitle from './utils/pageTitle';

const Work = ({active}) => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  }, []); 

  return (
    <div className={`elger-work ${active}`}>
        <PageTitle text='Dołącz do nas'/>
        <div className='work-content'>
                <img src='/electrician.jpg' alt='electrician'/>
                <img className='mobile' src='/electricianmini.jpg' alt='electrician'/>
              <div className='text-content'>
                <div>
                <p>Chcesz pracować w doświadczonej i uznanej na rynku firmie elektrycznej? 
                  <p>Dołącz do naszej załogi!
                  Wyślij nam swoje CV lub zadzwoń, dane kontaktowe znajdziesz 
                  <a href="https://elger.pl/kontakt" target="_parent" rel="noopener noreferrer">
                  {' TUTAJ'}.
                  </a>
                  </p>
                  </p>
                </div>
                  <div className='grid'>
                    <div className='box'><span><Rozwoj width='100px' height='100px'/></span><p>Rozwój pracowników</p></div>
                    <div className='box'><span><Motywacja  width='100px' height='100px'/></span><p>System motywacyjny</p></div>
                    <div className='box'><span><Ubezpieczenia  width='80px' height='100px'/></span><p>Ubezpieczenia pracownicze</p></div>
                    <div className='box'><span><Bezpieczenstwo  width='80px' height='100px'/></span><p>Bezpieczeństwo pracy</p></div>
                  </div>
              </div>
          </div>
    </div>
  );
};

export default Work;
