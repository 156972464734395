import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../App.scss';
import { ReactComponent as Office } from '../svg/office.svg';
import { ReactComponent as Email } from '../svg/email.svg';
import { ReactComponent as Phone } from '../svg/phone.svg';
import { ReactComponent as Route } from '../svg/route.svg';
import customMarker from '../svg/pin11.svg'; 
import PageTitle from './utils/pageTitle';

const customIcon = new L.Icon({
  iconUrl: customMarker,
  iconSize: [40, 40],
  iconAnchor: [20, 20],
  popupAnchor: [0, 155],
});

const DynamicMapCenter = ({ center }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(center, map.getZoom(), { animate: true });
  }, [center, map]);

  return null;
};

const Contact = ({ active }) => {
  const markers = [
    {
      position: [51.73531969035846, 19.404040526176733],
      location: "Oddział w Łodzi",
      address: "Pienista 49A/4, 94-109 Łódź",
      directions: "https://www.google.com/maps/dir//Pienista+49A",
      link: "https://www.google.com/maps/place/Pienista+49A"
    },
    {
      position: [50.01224801964448, 19.94755516956831],
      location: "Siedziba firmy",
      address: "Storczykowa 2, 30-612 Kraków",
      directions: "https://www.google.com/maps/dir//Us%C5%82ugi+Elektryczne+ELGER",
      link: "https://www.google.com/maps/place/Us%C5%82ugi+Elektryczne+ELGER"
    }
  ];

  const [centerX, setCenterX] = useState();
  const [centerY, setCenterY] = useState();
  const [scrollZoom, setScrollZoom] = useState(true);

  const calculateOffset = useCallback(() => {
    const width = window.innerWidth;

    let newOffsetX = 0;
    let newOffsetY = 0;

    if (width > 1800) {
      setCenterX(19.7 - 5)
      setCenterY(50.8)
      setScrollZoom(true);
    } else if (width > 1600 && width <= 1800) {
      setCenterX(19.7 - 4.2)
      setCenterY(50.8)
      setScrollZoom(true);
    } else if (width > 1300 && width <= 1600) {
      setCenterX(19.7 - 3.5)
      setCenterY(50.8)
      setScrollZoom(true);
    } else if (width > 1050 && width <= 1300) {
      setCenterX(19.7 - 3.2)
      setCenterY(50.8)
      setScrollZoom(true);
    } else if (width > 800 && width <= 1050) {
      setCenterX(19.7 - 2.5)
      setCenterY(50.8)
      setScrollZoom(true);
    } else if (width <= 800) {
      setCenterX(19.7)
      setCenterY(50.8 + 1.5)
      setScrollZoom(false);
    }

  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  }, []); 

  useEffect(() => {
    calculateOffset();
    window.addEventListener('resize', () => calculateOffset());
    return () => window.removeEventListener('resize', () => calculateOffset());
  }, [calculateOffset, window]);

  return (
    <div className={`elger-contact ${active}`}>
      <PageTitle text="Zapraszamy do kontaktu" />
      <div className="contact-flex">
        <div className="first-box">
          <p className="main-info">
            <span>Elger sp. z o.o.</span>
            <span>NIP 6793229857</span>
            <span>REGON 696969696</span>
            <span>KRS 69696969</span>
            <p className="contact-data">
              <p>
                <Office width="20px" height="20px" />
                Storczykowa 2, 30-612 Kraków
              </p>
              <p>
                <Email width="20px" height="20px" />
                <a href="mailto: biuro@elger.pl?subject=Zapytanie&body=Dzień dobry, ">biuro@elger.pl</a>
              </p>
            </p>
          </p>
          <div className="detail-info">
            <p className="contact-data">
              <span className="title">Oddział Łódź</span>
              <p>
                <Office width="20px" height="20px" />
                Pienista 49A/4 94-109 Łódź
              </p>
            </p>
            <p className="contact-data">
              <span className="title">Dział realizacji</span>
              <p>
                <Phone width="20px" height="20px" />
                <a href="tel:+48784607296">784 607 296</a>
              </p>
            </p>
            <p className="contact-data">
              <span className="title">Dział serwisu i&nbsp;gwarancji</span>
              <p>
                <Phone width="20px" height="20px" />
                <a href="tel:+48784607296">882 639 619</a>
              </p>
            </p>
          </div>
        </div>
        <div className="contact-map">
          <MapContainer
            center={[19.7, 50.8]}
            zoom={7}
            style={{ height: "100%", width: "100%" }}
            scrollWheelZoom={scrollZoom}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <DynamicMapCenter center={[centerY || 19.7, centerX || 50.8]} />
            {markers.map((marker, index) => (
              <Marker key={index} position={marker.position} icon={customIcon}>
                <Popup>
                  <div className="popup-content">
                    <div className="text">
                      <p className="address">
                        <span className="title">{marker.location}</span>
                        <span>{marker.address}</span>
                      </p>
                      <a href={marker.link} target="_blank" rel="noopener noreferrer">
                        Wyświetl większą mapę
                      </a>
                    </div>
                    <div className="directions">
                      <a href={marker.directions} target="_blank" rel="noopener noreferrer" title="Dojazd">
                        <Route width="35px" height="35px" />
                      </a>
                    </div>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default Contact;
