import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import '../App.scss';
import PageTitle from './utils/pageTitle';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';

import { ReactComponent as Next } from '../svg/next.svg';
import { ReactComponent as Prev } from '../svg/prev.svg';
import { ReactComponent as Close } from '../svg/close.svg';

const images = [
  { src: '/Ardekon_Składowa_referencje.jpg', caption: 'Ardekon', date: '07.2024' },
  { src: '/Dom Construction_GNC_referencje.jpg', caption: 'Dom Construction', date: '07.2024' },
  { src: '/R4R_referencje.jpg', caption: 'R4R', date: '09.2024' },
  { src: '/ECHO_Rydlówka_referencje.jpg', caption: 'ZAM Projekt ECHO-127', date: '11.2024' },
  { src: '/ELGER Referencje dot. Fuzji A w Łodzi.jpg', caption: 'Erbud', date: '09.2024' },
  { src: '/ELGER Referencje dot. Novum III Zadanie 1.jpg', caption: 'Erbud', date: '09.2024' },
  { src: '/INTER-BUD_referencje.jpg', caption: 'F.R.B. Inter-bud', date: '07.2024' },
  { src: '/Krakbau_referencje_2021-2024.jpg', caption: 'Krakbau', date: '07.2024' },
  { src: '/KRISBUD_Park Bednarskiego_referencje.jpg', caption: 'Krisbud', date: '07.2024' },
  { src: '/KRISBUD_Trzebinia A1A2_referencje.jpg', caption: 'Krisbud', date: '07.2024' },
];

const imagess = [
  { src: '/Ardekon_Składowa_referencje.jpg', caption: 'Ardekon sp. z o.o. s.k.', date: '07.2024' },
  { src: '/Dom Construction_GNC_referencje.jpg', caption: 'Dom Construction sp. z o.o.', date: '07.2024' },
  { src: '/R4R_referencje.jpg', caption: 'R4R sp. z o.o.', date: '09.2024' },
  { src: '/ECHO_Rydlówka_referencje.jpg', caption: 'ZAM Projekt ECHO-127 Sp z o.o. s.k.', date: '11.2024' },
  { src: '/ELGER Referencje dot. Fuzji A w Łodzi.jpg', caption: 'Erbud S.A.', date: '09.2024' },
  { src: '/ELGER Referencje dot. Novum III Zadanie 1.jpg', caption: 'Erbud S.A.', date: '09.2024' },
  { src: '/INTER-BUD_referencje.jpg', caption: 'F.R.B. Inter-bud sp. z o.o. s.k.', date: '07.2024' },
  { src: '/Krakbau_referencje_2021-2024.jpg', caption: 'Krakbau S.A.', date: '07.2024' },
  { src: '/KRISBUD_Park Bednarskiego_referencje.jpg', caption: 'Krisbud sp. z o.o.', date: '07.2024' },
  { src: '/KRISBUD_Trzebinia A1A2_referencje.jpg', caption: 'Krisbud sp. z o.o.', date: '07.2024' },
];



const Recomendations = ({ active }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const CustomPopup = ({ currentImageIndex, closePopup, handleNext, handlePrev, images }) => {
    const [startX, setStartX] = useState(null);
    const [deltaX, setDeltaX] = useState(0);

    const handleMouseDown = (e) => {
      setStartX(e.clientX);
    };

    const handleTouchStart = (e) => {
      setStartX(e.touches[0].clientX);
    };

    useEffect(() => {
      const handleMouseMove = (e) => {
        if (startX !== null) {
          setDeltaX(e.clientX - startX);
        }
      };

      const handleTouchMove = (e) => {
        if (startX !== null) {
          setDeltaX(e.touches[0].clientX - startX);
        }
      };

      const handleEnd = () => {
        if (startX !== null) {
          if (deltaX > 50 && currentImageIndex > 0) {
            handlePrev();
          } else if (deltaX < -50 && currentImageIndex < images.length - 1) {
            handleNext();
          }
          setStartX(null);
          setDeltaX(0);
        }
      };

      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleEnd);

      return () => {
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleEnd);
      };
    }, [startX, deltaX, currentImageIndex, handlePrev, handleNext, images]);

    return (
      <div
        className="custom-popup"
        onClick={(e) => e.target.classList.contains('custom-popup') && closePopup()}
      >
        <div
          className="popup-content"
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
        >
          <button className="popup-close" onClick={closePopup}>
            <div>{`${currentImageIndex + 1} / ${images?.length}`}</div>
            <Close width="15px" height="15px" />
          </button>
          <div className="popup-image">
            <img src={images[currentImageIndex].src} alt={images[currentImageIndex].caption} />
          </div>
          <div className="popup-navigation">
            <button onClick={handlePrev} disabled={currentImageIndex === 0}>
              <Prev width="30px" height="30px" />
            </button>
            <button onClick={handleNext} disabled={currentImageIndex === images.length - 1}>
              <Next width="30px" height="30px" />
            </button>
          </div>
        </div>
      </div>
    );
  };


  const openPopup = (index) => {
    setCurrentImageIndex(index);
    setPopupOpen(true);

    const header = document.querySelector('header.elger-header.pages')
    const navbar = document.querySelector('.navbar-container')
    const footer = document.querySelector('.elger-footer')
    if(navbar){
        navbar.style.zIndex = -1
    }
    if(header){
        header.style.zIndex = -1
    }
    if(footer){
      footer.style.zIndex = -1;
    }
  };

  const closePopup = () => {
    setPopupOpen(false);

    const header = document.querySelector('header.elger-header.pages')
    const navbar = document.querySelector('.navbar-container')
    const footer = document.querySelector('.elger-footer')
      if(navbar){
        navbar.style.zIndex = 500;
      }
      if(header){
        header.style.zIndex = 600;
      }
      if(footer){
        footer.style.zIndex = 0;
      }
  };

  const handleNext = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  }, []); 
  
  return (
    <div className={`elger-recomendations ${active}`} >
        <PageTitle text="Rekomendacje naszych kontrahentów" />
        <div className='recomendations-image' >  
        <p className='recomendations-text' >
          <span>
            Przedstawiamy wybrane rekomendacje naszych klientów, które są dla nas dowodem zaufania i&nbsp;satysfakcji z&nbsp;naszej współpracy. 
          Jesteśmy dumni, że&nbsp;możemy być częścią sukcesów naszych klientów i&nbsp;cieszymy się, że&nbsp;nasze działania znajdują uznanie w&nbsp;ich oczach.
          </span>
          
        </p>
        <div className="slider-container" style={{
      backgroundImage: `url(../rec4.jpg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
}} >
      <Swiper
        modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        spaceBetween={0}
        slidesPerView={3}
        loop={true}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          320: { slidesPerView: 1 },
          500: { slidesPerView: 2 },
          630: { slidesPerView: 3 },
          900: { slidesPerView: 4 },
          1100: { slidesPerView: 5 },
        }}
      >
        {images.map((img, index) => (
          <SwiperSlide key={index} onClick={() => openPopup(index)}>
            <div className="slider-item">
              <img src={img.src} alt={img.caption} />
              <div className="item-caption">
                <p>{img.caption}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
      {popupOpen && (
        <CustomPopup
          currentImageIndex={currentImageIndex}
          closePopup={closePopup}
          handleNext={handleNext}
          handlePrev={handlePrev}
          images={images}
        />
      )}
    </div>
    </div>
  );
};

export default Recomendations;
