import React from 'react';
import '../App.scss';

const icons = [
  { src: "/archicom-logo.png" },
  { src: "/archiinwest-logo.png" },
  { src: "/chemobudowa-logo.png" },
  { src: "/domconstruction-logo.png" },
  { src: "/echoinvestments-logo.png" },
  { src: "/erbud-logo.png" },
  { src: "/instal-logo.png" },
  { src: "/interbud-logo.png" },
  { src: "/krakbau-logo.png" },
  { src: "/krisbud-logo.png" },
  { src: "/lokumdeveloper-logo.png" },
  { src: "/legprzem-logo.png" },
  { src: "/proton-logo.png" },
  { src: "/r4r-logo.png" },
  { src: "/zabbud-logo.png" },
];

const Footer = () => {
  return (
    <div className='footer-content'>
      <div className='info'>
        <div className='map'></div>
        <div className='line'></div>
        <div className='contact'></div>
      </div>
        {/* <a href="https://wizytowka.rzetelnafirma.pl/GI2QR5IW"><img width="150px" src='/baner.jpg'/></a> */}
      <p>&copy; 2024 Elger. Wszelkie prawa zastrzeżone.</p>
    </div>
  );
}

export default Footer;
