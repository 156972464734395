import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../App.scss';
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Menu } from '../svg/menu.svg';
import { ReactComponent as Plug } from '../svg/plug.svg';
import { ReactComponent as Home } from '../svg/home-menu.svg';

const Navbar = () => {
  const menuRef = useRef(null);
  const location = useLocation(); 
  const [isMobile, setIsMobile] = useState(false)
  const [isOpen, setIsOpen] = useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  useEffect(() => {
    const checkWindowWidth = () => {
      if (window.innerWidth <= 720){
        setIsMobile(true)
      } else{
        setIsMobile(false)
      }
    }

    checkWindowWidth();

    window.addEventListener('resize', checkWindowWidth);
    return () => window.removeEventListener('resize', checkWindowWidth);
  }, [window]);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.elger-routes > .navbar-container');
      
      if (window.scrollY > 10) {
        if (navbar) {
          setIsOpen(false);
        }
      } else {
        if (navbar) {
          setIsOpen(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMobileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsMobileOpen(false); 
  }, [location]);

  const handleClick = useCallback(() => {
    if(isMobile){
      setIsMobileOpen(true); 
    } else{
      window.scrollTo({ top: 0, behavior: 'smooth' }); 
    }
  },[isMobile]);

  const toggleMenu = useCallback(() => {
    setIsMobileOpen(false);
  },[]);


return isMobile ? (
  <div className={`navbar-container-mobile ${isMobileOpen ? 'visible' : 'hidden'}`}
>
    {!isMobileOpen && <div className="navbar-icon" onClick={handleClick}>
      <Menu width="24px" height="24px" />
    </div>}
    {!isMobileOpen && <div className="overlay-name">
      <p>ELGER sp. z o.o.</p>
    </div>}
    <nav
      ref={menuRef} 
      className={`navbar-mobile ${isMobileOpen ? 'visible' : 'hidden'}`}
      style={{
        backgroundImage: `url(../headerek.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'right top',
        backgroundRepeat: 'no-repeat'}}
    >
        <div className="navbar-logo">
        <p>ELGER sp. z o.o.</p>
        <span>-usługi elektryczne</span>
      </div>
      <ul className="navbar-menu">
        <li>
          <NavLink to="/" activeclassname="active">
            Strona główna
          </NavLink>
        </li>
        <li>
          <NavLink to="/realizacje" activeclassname="active">
            Realizacje
          </NavLink>
        </li>
        <li>
          <NavLink to="/rekomendacje" activeclassname="active">
            Rekomendacje
          </NavLink>
        </li>
        <li>
          <NavLink to="/praca" activeclassname="active">
            Praca
          </NavLink>
        </li>
        <li>
          <NavLink to="/kontakt" activeclassname="active">
            Kontakt
          </NavLink>
        </li>
      </ul>
      <div className='empty-box'>
        <div>
        <Plug width='70px' height='70px'/>
        {/* <span>Z prądem i pod prąd</span> */}
        </div>
      </div>
    </nav>
  </div>
)
:
(
    <div className={`navbar-container`}>
      <div className={`navbar-icon ${isOpen ? 'hidden' : 'visible'}`} onClick={handleClick}>
        <Menu width="20px" height="20px" />
      </div>
      <nav
        className={`navbar ${isOpen ? 'visible' : 'hidden'}`}
      >
        <div className="navbar-logo">
          <p>ELGER sp. z o.o.</p>
        </div>
        <ul className="navbar-menu">
          <li>
            <NavLink to="/" activeclassname="active">
              <Home width="15px" height="15px"/>
            </NavLink>
          </li>
          <li>
            <NavLink to="/realizacje" activeclassname="active">
              Realizacje
            </NavLink>
          </li>
          <li>
            <NavLink to="/rekomendacje" activeclassname="active">
              Rekomendacje
            </NavLink>
          </li>
          <li>
            <NavLink to="/praca" activeclassname="active">
              Praca
            </NavLink>
          </li>
          <li>
            <NavLink to="/kontakt" activeclassname="active">
              Kontakt
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
