import React from 'react';
import '../App.scss';


  const Overlay = () => {
    return (
      <div className="overlay" style={{
        backgroundImage: `url(../headerek1.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className="overlay-image">
          <div className="overlay-title">
          <p>USŁUGI ELEKTRYCZNE</p>
          <span>- na rynku od 2001 roku</span>
          </div>
        </div>
      </div>
    );
  }

export default Overlay;
