import React, { useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './components/footer';
import Navbar from './components/menu';
import Home from './components/home';
import Recomendations from './components/recomendation';
import Realizations from './components/realizations';
import Work from './components/work';
import Contact from './components/contact';
import Overlay from '../src/components/overlay'

function App() {
  const location = useLocation();

  const isActive = useCallback((path) =>{
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
    if(!path && location.pathname !== '/'){
      return 'pages'
    } else if(location.pathname === path) {
      return path === '/' ? 'active' : 'elger-routes active'
    } else return path === '/' ? '' : 'elger-routes'
  },[location])

  return (
    <>
    <div className={`elger-container ${isActive()}`}
    style={{
      backgroundImage: `url(../background.jpg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'right center',
      backgroundRepeat: 'no-repeat'
    }}>
      <header className={`elger-header ${isActive()}`}>
        <Navbar />
        <Overlay/>
      </header>
      <main className={`elger-page ${isActive()}`}
      >
        <Routes>
          <Route path="/" element={<Home active={isActive('/')}/>} />
          <Route path="/realizacje" element={<Realizations active={isActive('/realizacje')} />} />
          <Route path="/rekomendacje" element={<Recomendations active={isActive('/rekomendacje')} />} />
          <Route path="/praca" element={<Work active={isActive('/praca')} />} />
          <Route path="/kontakt" element={<Contact active={isActive('/kontakt')} />} />
        </Routes>
      </main>


      </div>
      <footer className="elger-footer">
      <Footer />
    </footer>
      </>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
