import React, { useState, useEffect } from 'react';
import PageTitle from './utils/pageTitle';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import '../App.scss';
import { Pagination, EffectCoverflow } from 'swiper/modules';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
} from '@tanstack/react-table';

const mieszkalne = [
  {
    name: "Osiedle „Wiślane Tarasy”- bydynki A, D, W",
    localization: "ul.Grzegórzecka, Kraków"
  },
  {
    name: "Osiedle „Piaskowy Zakątek”",
    localization: "ul.Piaskowa, Kraków"
  },
  {
    name: "Budynek mieszkalny wielorodzinny",
    localization: "ul.Tarnobrzeska, Kraków"
  },
  {
    name: "Budynek mieszkalny wielorodzinny",
    localization: "ul.Chełmońskiego, Kraków"
  },
  {
    name: "Osiedle „Górka Narodowa”- budynki B1, B2, B3, C1, C3",
    localization: "ul.Zauchy, Kraków"
  },
  {
    name: "Osiedle „29 Aleja”- budynek B",
    localization: "ul.Prandoty, Kraków"
  },
  {
    name: "Osiedle „Bonarka Living”- budynki B, C, D",
    localization: "ul.Puszkarska, Kraków"
  },
  {
    name: "Budynek mieszkalny wielorodzinny Sensity",
    localization: "ul.Wielicka, Kraków"
  },
  {
    name: "Osiedle „Lokum Vista”- budynek C1",
    localization: "ul.Walerego Sławka, Kraków"
  },
  {
    name: "Osiedle „Lokum Salsa”- budynki B i C",
    localization: "ul.Klimeckiego, Kraków"
  },
  {
    name: "Osiedle „Grzegórzki Park”- budynki A4, A5, A6, A7, A8",
    localization: "ul.Cystersów, Kraków"
  },
  {
    name: "Osiedle „Podgaje”- etap I-VI",
    localization: "ul.Domagały, Kraków"
  },
  {
    name: "Osiedle „Mierzeja Wiślana”- budynek B1",
    localization: "ul.Mierzeja Wiślana, Kraków"
  },
  {
    name: "Budynki mieszkalne wielorodzinne",
    localization: "ul.Czarodziejska, Kraków"
  },
  {
    name: "Osiedle „Novum 2”- budynki 1 i 2",
    localization: "ul.Rakowicka, Kraków"
  },
  {
    name: "Osiedle domów typu „bliźniak”",
    localization: "ul.Koszutki, Kraków"
  },
  {
    name: "Budynek mieszkalny wielorodzinny A",
    localization: "ul.Białoruska, Kraków"
  },
  {
    name: "Budynek mieszkalny wielorodzinny C",
    localization: "ul.Rydlówka, Kraków"
  },
  {
    name: "Budynek mieszkalny wielorodzinny",
    localization: "ul.Składowa, Kraków"
  },
  {
    name: "Osiedle „Vidok”- budynki A1, A2",
    localization: "ul.Młoszowska, Trzebinia"
  },
  {
    name: "Budynek mieszkalny wielorodzinny „Trójkąt”",
    localization: "ul.Piłsudskiego, Myślenice"
  },
  {
    name: "Osiedle „Fuzja”- budynki A, F, G-01",
    localization: "ul.Tymienieckiego, Łodź"
  },
  {
    name: "Osiedle „Zenit”- budynek 2D",
    localization: "ul.Widzewska, Łodź"
  },
  {
    name: "Osiedle „Premier Park”- budynki D1, D2, D3, C3",
    localization: "ul.Szwana, Katowice"
  },
  {
    name: "Budynki mieszkalne jednorodzinne z instalacją smart-home Grenton",
    localization: "ul.Skoczylasa, Kraków"
  }
];

const niemieszkalne = [
  {
    name: "Budynek hotelowo- usługowy „A”",
    localization: "ul.Puszkarska, Kraków"
  },
  {
    name: "Budynek hotelowo- usługowy „R4R”",
    localization: "ul.Kilińskiego, Łodź"
  },
  {
    name: "Budynek biurowy „Wilga Business Centre” wraz z wykonaniem aranżacji instalacji elektrycznych w lokalach biurowych",
    localization: "ul.Marcika, Kraków"
  },
  {
    name: "Budynek biurowy „Duo Office Argento” wraz z wykonaniem aranżacji instalacji elektrycznych w lokalach biurowych",
    localization: "ul.Marcika, Kraków"
  },
  {
    name: "Budynek biurowy „Duo Office Oro” wraz z wykonaniem aranżacji instalacji elektrycznych w lokalach biurowych",
    localization: "ul.Marcika, Kraków"
  },
  {
    name: "Przebudowa pomieszczeń Oddziału Ortopedii, budynek nr 4, Wojskowy Szpital Kliniczny",
    localization: "ul.Wrocławska, Kraków"
  },
  {
    name: "Budynek usługowo- produkcyjny, zakład produkcji mebli „Prato",
    localization: "ul.Piastowska w Kocmyrzowie"
  },
  {
    name: "Zakład przetwórstwa drobiu „Plukon”- wykonanie instalacji odgromowej",
    localization: "ul.Główna, Dąbrowa Górnicza"
  },
  {
    name: "Zakład produkcyjny urządzeń ciśnieniowych „Polimer”- modernizacja instalacji elektrycznej hali produkcyjnej",
    localization: "ul.Ciepłownicza, Kraków"
  },
  {
    name: "Wykonanie połączeń elektrycznych grzałek w podgrzewaczu azotu TB2560E dla przedsiębiorstwa „Polimer”",
    localization: "ul.Ciepłownicza, Kraków"
  },
  {
    name: "Sklep „Leclerc”- modernizacja instalacji oświetlenia podstawowego i awaryjnego wraz z wymianą opraw",
    localization: "ul.Limanowskiego, Ostrów Wielkopolski"
  },
  {
    name: "Sklep „Leclerc”- modernizacja instalacji oświetlenia podstawowego i awaryjnego wraz z wymianą opraw",
    localization: "ul.Tarnogórska, Gliwice"
  },
  {
    name: "Adaptacja piętra budynku usługowego na potrzeby żłobka „Niebieska Żabka”",
    localization: "ul.Domagały, Kraków"
  },
  {
    name: "Modernizacja instalacji zasilania wraz z wymianą kompletu rozdzielnic w budynku mieszkalnym wielorodzinnym",
    localization: "ul.Mogilska 121, Kraków"
  },
  {
    name: "Wykonanie zasilania opraw oświetlenia zewnętrznego, wraz z wykonaniem robót ziemnych",
    localization: "ul.Park Bednarskiego, Kraków"
  },
  {
    name: "Modernizacja instalacji zasilania głównego i wyłącznika pożarowego budynku, sklep „Max- Fliz”",
    localization: "ul.Zakopiańska, Kraków"
  },
  {
    name: "Modernizacja instalacji elektrycznych w budynku Urzędu Gminy",
    localization: "ul.Długa w Rzezawie"
  },
  {
    name: "Modernizacja instalacji elektrycznych i niskoprądowych wraz z wymianą wewnętrznych linii zasilających w kamienicy",
    localization: "ul.Starowiślna, Kraków"
  },
  {
    name: "Modernizacja instalacji elektrycznych i niskoprądowych wraz z wymianą wewnętrznych linii zasilających w kamienicy",
    localization: "ul.Urzędnicza 29, Kraków"
  },
  {
    name: "Wykonanie instalacji odgromowej na dachu ujeżdżalni koni",
    localization: "Czułów"
  }
];

const photo = [
  { name: "Budynek biurowy „Duo Office Argento”, ul. Marcika w Krakowie", src: "/realizacje/Budynek biurowy „Duo Office Argento”.jpg" },
  { name: "Budynek biurowy „Duo Office Oro”, ul. Marcika w Krakowie", src: "/realizacje/Budynek biurowy „Duo Office Oro”.jpg" },
  { name: "Budynek biurowy „Wilga Business Centre”, ul. Marcika w Krakowie", src: "/realizacje/Budynek biurowy „Wilga Business Centre” wraz z wykonaniem aranżacji instalacji elektrycznych w lokalach biurowych, ul. Marcika w Krakowie.jpg" },
  { name: "Budynek hotelowo-usługowy „R4R”, ul. Kilińskiego w Łodzi", src: "/realizacje/Budynek hotelowo- usługowy „R4R”, ul. Kilińskiego w Łodzi.jpg" },
  { name: "Budynek mieszkalny wielorodzinny A, ul. Białoruska w Krakowie", src: "/realizacje/Budynek mieszkalny wielorodzinny A, ul. Białoruska w Krakowie.jpg" },
  { name: "Budynek mieszkalny wielorodzinny C, ul. Rydlówka w Krakowie", src: "/realizacje/Budynek mieszkalny wielorodzinny C, ul. Rydlówka w Krakowie.jpg" },
  { name: "Budynek mieszkalny wielorodzinny Sensity, ul. Wielicka w Krakowie", src: "/realizacje/Budynek mieszkalny wielorodzinny Sensity, ul. Wielicka w Krakowie.jpg" },
  { name: "Budynek mieszkalny wielorodzinny, ul. Składowa w Krakowie-moderno-łobzów", src: "/realizacje/Budynek mieszkalny wielorodzinny, ul. Składowa w Krakowie-moderno-lobzow.jpg" },
  { name: "Budynek usługowo-produkcyjny, zakład produkcji mebli „Prato”, ul. Piastowska w Kocmyrzowie", src: "/realizacje/Budynek usługowo- produkcyjny, zakład produkcji mebli „Prato”, ul. Piastowska w Kocmyrzowie.jpg" },
  { name: "Osiedle „29 Aleja”- budynek B, ul. Prandoty w Krakowie", src: "/realizacje/Osiedle „29 Aleja”- budynek B, ul. Prandoty w Krakowie.jpg" },
  { name: "Osiedle „Bonarka Living”- budynki B, C, D, ul. Puszkarska w Krakowie", src: "/realizacje/Osiedle „Bonarka Living”- budynki B, C, D, ul. Puszkarska w Krakowie.jpg" },
  { name: "Osiedle „Fuzja”- budynki A, F, G-01, ul. Tymienieckiego w Łodzi", src: "/realizacje/Osiedle „Fuzja”- budynki A, F, G-01, ul. Tymienieckiego w Łodzi.jpg" },
  { name: "Osiedle „Górka Narodowa”- budynki B1, B2, B3, C1, C3, ul. Zauchy w Krakowie", src: "/realizacje/Osiedle „Górka Narodowa”- budynki B1, B2, B3, C1, C3, ul. Zauchy w Krakowie.jpg" },
  { name: "Osiedle „Grzegórzki Park”- budynki A4, A5, A6, A7, A8, ul. Cystersów w Krakowie", src: "/realizacje/Osiedle „Grzegórzki Park”- budynki A4, A5, A6, A7, A8, ul. Cystersów w Krakowie.jpg" },
  { name: "Osiedle „Lokum Salsa”- budynki B i C, ul. Klimeckiego w Krakowie", src: "/realizacje/Osiedle „Lokum Salsa”- budynki B i C, ul. Klimeckiego w Krakowie.jpg" },
  { name: "Osiedle „Lokum Vista”- budynek C1, ul. Walerego Sławka w Krakowie", src: "/realizacje/Osiedle „Lokum Vista”- budynek C1, ul. Walerego Sławka w Krakowie.jpg" },
  { name: "Osiedle „Mierzeja Wiślana”- budynek B, ul. Mierzeja Wiślana w Krakowie", src: "/realizacje/Osiedle „Mierzeja Wiślana”- budynek B1, ul. Mierzeja Wiślana w Krakowie.jpg" },
  { name: "Osiedle „Novum 2”- budynki 1 i 2, ul. Rakowicka w Krakowie", src: "/realizacje/Osiedle „Novum 2”- budynki 1 i 2, ul. Rakowicka w Krakowie.jpg" },
  { name: "Osiedle „Piaskowy Zakątek” ul. Piaskowa, Kraków", src: "/realizacje/Osiedle „Piaskowy Zakątek” ul. Piaskowa, Kraków.jpg" },
  { name: "Osiedle „Podgaje”- etap I-V, ul. Domagały w Krakowie", src: "/realizacje/Osiedle „Podgaje”- etap I-VI, ul. Domagały w Krakowie.jpg" },
  { name: "Osiedle „Premier Park”- budynki D1, D2, D3, C3 ul. Szwana w Katowicach", src: "/realizacje/Osiedle „Premier Park”- budynki D1, D2, D3, C3 ul. Szwana w Katowicach.jpg" },
  { name: "Osiedle „Wiślane Tarasy”- bydynki A, D, W ul. Grzegórzecka, Kraków", src: "/realizacje/Osiedle „Wiślane Tarasy”- bydynki A, D, W ul. Grzegórzecka, Kraków.jpg" },
  { name: "Osiedle „Zenit”- budynek 2D, ul. Widzewska w Łodzi", src: "/realizacje/Osiedle „Zenit”- budynek 2D, ul. Widzewska w Łodzi.jpg" }
]


const FilterableTable = ({ data, columns, filter, onFilterChange }) => {
  const table = useReactTable({
    data,
    columns,
    state: { globalFilter: filter },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: onFilterChange,
  });

  if (data.length === 0) {
    return <div className="no-data-message">Brak danych dla podanej frazy</div>;
  }

  return (
    <div className="table-container">
      <table className="styled-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                key={cell.id}
                className={cell.column.id === 'localization' ? 'localization' : 'building'}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};



const Realizations = ({ active }) => {
  const [filter, setFilter] = useState('all');
  const [search, setSearch] = useState('');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  }, []); 

  const mieszkalneWithType = mieszkalne.map((item) => ({ ...item, type: 'mieszkalne' }));
  const niemieszkalneWithType = niemieszkalne.map((item) => ({ ...item, type: 'niemieszkalne' }));

  const allProjects = mieszkalneWithType.concat(niemieszkalneWithType);

  const filteredProjects =
    filter === 'all'
      ? allProjects
      : allProjects.filter((project) => project.type === filter);

  const searchedProjects = filteredProjects.filter((project) =>
    project.name.toLowerCase().includes(search.toLowerCase()) 
    || project.localization.toLowerCase().includes(search.toLowerCase())
  );

  const columns = [
    { accessorKey: 'name', header: 'Budynek' },
    { accessorKey: 'localization', header: 'Lokalizacja' },
  ];

  return (
    <div className={`elger-realizations ${active}`}>
      <div className="realizations-content">
        <PageTitle text="Wybrane realizacje" />
        <div className="realizations-swiper">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {photo.map((photo, index) => (
              <SwiperSlide key={index}>
                <img src={photo.src} alt={photo.name} width={400} />
                <div className="name">{photo.name}</div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="realizations">
          <div className="filters">
            <div className="buttons">
              <button className='all' onClick={() => setFilter('all')}>Wszystkie</button>
              <button className='home' onClick={() => setFilter('mieszkalne')}>Mieszkalne</button>
              <button className='office' onClick={() => setFilter('niemieszkalne')}>Pozostałe</button>
            </div>
            <input
              type="text"
              placeholder="Szukaj realizacji..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <FilterableTable
            data={searchedProjects}
            columns={columns}
            filter={search}
            onFilterChange={setSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default Realizations;
